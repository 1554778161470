<template>
  <div class="blank">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.blank {
  min-height: 100%;
  padding: 2rem;
  background: #ffffff;
  font-size: 1rem;

  :deep(h1) { font-size: 1.5rem; }
  :deep(h2) { font-size: 1.25rem; }
}
</style>
